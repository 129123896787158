
import React from 'react'
import { Popover, Menu } from '@apex/ui'
import { Plus, Minus, ChevronRight, ChevronLeft, Copy } from 'react-feather'

/**
 * Numeric field filter options.
 */

const numericOptions = [
  { icon: Plus, label: 'Show matching', op: 'show' },
  { icon: Minus, label: 'Hide matching', op: 'hide' },
  { icon: ChevronRight, label: 'Show above', op: 'above' },
  { icon: ChevronLeft, label: 'Show below', op: 'below' },
  { icon: Copy, label: 'Copy to clipboard', op: 'copy' }
]

/**
 * Default field filter options.
 */

const stringOptions = [
  { icon: Plus, label: 'Show matching', op: 'show' },
  { icon: Minus, label: 'Hide matching', op: 'hide' },
  { icon: Copy, label: 'Copy to clipboard', op: 'copy' }
]

/**
 * FieldFilter component.
 */

export default function FieldFilter({ name, value, children, onSelect, position = 'bottom' }) {
  function content({ close }) {
    const options = typeof value == 'number'
      ? numericOptions
      : stringOptions

    return <Menu full small>
      {options.map(o => {
        function select() {
          close()
          onSelect({ op: o.op, name, value })
        }
        return <Menu.Item key={o.op} icon={o.icon} onSelect={select}>
          {o.label}
        </Menu.Item>
      })}
    </Menu>
  }

  return <Popover content={content} position={position}>
    {children}
  </Popover> 
}
