
import React from 'react'
import { Button } from '@apex/ui'
import { List } from 'react-feather' 

/**
 * QueryHistoryButton component.
 */

export default function QueryHistoryButton({ ...props }) {
  return <Button
    is="a"
    icon={List}
    theme="minimal"
    title="Show query history."
    {...props} />
}

