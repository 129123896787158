
import React from 'react'
import { Paragraph } from '@apex/ui'

/**
 * SheetPlaceholder component.
 */

export default function SheetPlaceholder({ text }) {
  return <Paragraph padding={4} muted>{text}</Paragraph>
}