
import Client from '@apex/lib/Client'

/*
 * Client is the API client.
 */

export default class extends Client {
  // Query performs a query.
  async Query(params) {
    return this._request("query", params)
  }

  // Search performs a search.
  async Search(params) {
    return this._request("search", params)
  }

  // GetAlert returns an alert.
  async GetAlert(params) {
    return this._request("get_alert", params)
  }

  // AddAlert adds an alert.
  async AddAlert(params) {
    return this._request("add_alert", params)
  }

  // UpdateAlert updates an alert.
  async UpdateAlert(params) {
    return this._request("update_alert", params)
  }

  // RemoveAlert removes an alert.
  async RemoveAlert(params) {
    return this._request("remove_alert", params)
  }

  // TestAlert tests an alert with dummy data.
  async TestAlert(params) {
    return this._request("test_alert", params)
  }

  // GetAlerts returns all alerts for a project.
  async GetAlerts(params) {
    return this._request("get_alerts", params)
  }

  // AddSearch adds a saved search.
  async AddSearch(params) {
    return this._request("add_search", params)
  }

  // UpdateSearch updates a saved search.
  async UpdateSearch(params) {
    return this._request("update_search", params)
  }

  // RemoveSearch removes a saved search.
  async RemoveSearch(params) {
    return this._request("remove_search", params)
  }

  // GetSearches returns all saved search for a project.
  async GetSearches(params) {
    return this._request("get_searches", params)
  }

  // AddToken creates a new token.
  async AddToken(params) {
    return this._request("add_token", params)
  }

  // RemoveToken removes a token.
  async RemoveToken(params) {
    return this._request("remove_token", params)
  }

  // GetTokens returns all tokens in a project.
  async GetTokens(params) {
    return this._request("get_tokens", params)
  }

  // GetNotification returns a notification.
  async GetNotification(params) {
    return this._request("get_notification", params)
  }

  // AddNotification adds a notification.
  async AddNotification(params) {
    return this._request("add_notification", params)
  }

  // UpdateNotification updates a notification.
  async UpdateNotification(params) {
    return this._request("update_notification", params)
  }

  // RemoveNotification removes a notification.
  async RemoveNotification(params) {
    return this._request("remove_notification", params)
  }

  // GetNotifications returns all alerts for a project.
  async GetNotifications(params) {
    return this._request("get_notifications", params)
  }

  // AddEvents adds a batch of events.
  async AddEvents(params) {
    return this._request("add_events", params)
  }

  // AddProject adds a new project.
  async AddProject(params) {
    return this._request("add_project", params)
  }

  // UpdateProject updates a new project.
  async UpdateProject(params) {
    return this._request("update_project", params)
  }

  // RemoveProject removes a project and its logs.
  async RemoveProject(params) {
    return this._request("remove_project", params)
  }

  // GetProjects returns all projects.
  async GetProjects() {
    return this._request("get_projects")
  }

  // GetProjectStats return project stats.
  async GetProjectStats(params) {
    return this._request("get_project_stats", params)
  }

  // GetDiscoveredFields returns fields discovered.
  async GetDiscoveredFields(params) {
    return this._request("get_discovered_fields", params)
  }

  // GetTimeseries returns event timeseries.
  async GetTimeseries(params) {
    return this._request("get_timeseries", params)
  }

  // GetLevelTimeseries returns event level timeseries.
  async GetLevelTimeseries(params) {
    return this._request("get_level_timeseries", params)
  }

  // GetNumericFieldStats returns field stats.
  async GetNumericFieldStats(params) {
    return this._request("get_numeric_field_stats", params)
  }

  // GetStringFieldStats returns field stats.
  async GetStringFieldStats(params) {
    return this._request("get_string_field_stats", params)
  }
  
  // GetBooleanFieldStats returns field stats.
  async GetBooleanFieldStats(params) {
    return this._request("get_boolean_field_stats", params)

  }
}
