
import React, { useEffect } from 'react'
import usePending from '@apex/lib/hooks/usePending'
import useFocus from '@apex/lib/hooks/useFocus'
import useRedirect from './lib/useRedirect'
import {
  Button,
  Form,
  Loader,
  Page,
  SelectField,
  TextInputField,
  toaster
} from '@apex/ui'

const regions = {
  americas: [
    { id: "us-west4", name: "Las Vegas" },
    { id: "us-west2", name: "Los Angeles" },
    { id: "northamerica-northeast1", name: "Montréal" },
    { id: "us-west1", name: "Oregon" },
    { id: "us-east4", name: "Northern Virginia" },
    { id: "southamerica-east1", name: "São Paulo" },
  ],
  europe: [
    { id: "europe-north1", name: "Finland" },
    { id: "europe-west2", name: "London" },
    { id: "europe-west6", name: "Zürich" },
    { id: "europe-west1", name: "Belgium" },
    { id: "europe-west4", name: "Netherlands" }
  ],
  asia: [
    { id: "asia-east2", name: "Hong Kong" },
    { id: "asia-south1", name: "Mumbai" },
    { id: "asia-northeast2", name: "Osaka" },
    { id: "asia-east1", name: "Taiwan" },
    { id: "asia-northeast1", name: "Tokyo" },
    { id: "asia-northeast3", name: "Seoul" },
    { id: "asia-southeast1", name: "Singapore" },
    { id: "australia-southeast1", name: "Sydney" }
  ]
}

/**
 * ProjectEditPage component.
 */

export default function ProjectEditPage({ project_id, projects, actions, ...props }) {
  const [redirect, saved] = useRedirect(`/projects`)
  const isNew = project_id == null

  // load projects
  useEffect(_ => {
    actions.loadProjects()
  }, [project_id])

  // pending projects
  if (projects == null) {
    return <Loader />
  }

  // existing project
  const project = projects.find(p => p.id == project_id)

  // TODO: handle missing project

  async function onSubmit(project) {
    if (isNew) {
      await actions.addProject(project)
    } else {
      await actions.updateProject(project)
    }

    toaster.success('Saved project')
    saved()
  }

  return <React.Fragment>
    {redirect}
    <Page.Title text={isNew ? 'Add project' : 'Edit project'} />
    <Page.Content.Wrapper>
      <Project
        {...(project || {})}
        onSubmit={onSubmit} />
    </Page.Content.Wrapper>
  </React.Fragment>
}

/**
 * Project component.
 */

function Project({ id, name, description, retention = 30, location, mode = 'structured', created_at, onSubmit }) {
  const focus = useFocus()

  const [pending, submit] = usePending(e => {
    return onSubmit({
      ...e,
      id,
      location: id == null ? e.location : location,
      mode: id == null ? e.mode : mode,
      retention: parseInt(e.retention, 10)
    })
  })

  function ID({ id }) {
    if (!id) return null
    return <Form.Row>
      <TextInputField
        name="id"
        label="ID"
        defaultValue={id}
        hint="The project identifier, use this in API calls."
        required
        disabled />
    </Form.Row>
  }

  return <Form onSubmit={submit}>
    <ID id={id} />
    <input type="hidden" name="created_at" value={created_at} />

    <Form.Row>
      <TextInputField
        innerRef={focus}
        name="name"
        label="Name"
        placeholder="Web Production"
        defaultValue={name}
        hint="A human-friendly project name."
        autoComplete="none"
        required />
    </Form.Row>

    <Form.Row>
      <TextInputField
        name="description"
        label="Description"
        placeholder="The production web application."
        defaultValue={description}
        hint="A short description of your project."
        autoComplete="none" />
    </Form.Row>

    <Form.Row>
      <TextInputField
        type="number"
        name="retention"
        label="Retention"
        placeholder="0"
        defaultValue={retention}
        hint="The log retention in days. When zero the logs do not expire." />
    </Form.Row>

    <Form.Row>
      <LocationSelect
        name="location"
        label="Location"
        defaultValue={location}
        disabled={id != null}
        hint="The region where the log events are stored, choose a location near your servers to improve latency." />
    </Form.Row>

    <Form.Row>
      <ModeSelect
        name="mode"
        label="Storage mode"
        defaultValue={mode}
        disabled={id != null}
        hint="The storage mode, optimized for plain-text or structured logs. Both options support plain-text and structured logging, however, the structured mode shards on the `message` value, restricting its length to 1024 bytes." />
    </Form.Row>

    <Form.Actions>
      <Button theme="primary" pending={pending}>Save project</Button>
    </Form.Actions>
  </Form>
}

/**
 * ModeSelect component.
 */

function ModeSelect({ ...props }) {
  return <SelectField {...props}>
    <option value="structured">Structured</option>
    <option value="plain_text">Plain-text</option>
  </SelectField>
}

/**
 * LocationSelect component.
 */

function LocationSelect({ ...props }) {
  return <SelectField {...props}>
    <optgroup label="Americas">
      <Regions list={regions.americas} />
    </optgroup>
    
    <optgroup label="Europe">
      <Regions list={regions.europe} />
    </optgroup>
    
    <optgroup label="Asia Pacific">
      <Regions list={regions.asia} />
    </optgroup>
  </SelectField>
}

/**
 * Regions component.
 */

function Regions({ list }) {
  return list.sort(byName).map(r => {
    return <option value={r.id}>{r.name}</option>
  })
}

/**
 * byName sort function.
 */

function byName(a, b) {
  return a.name.localeCompare(b.name)
}