
import React from 'react'
import FieldFilter from './FieldFilter'
import useConfig from './lib/useConfig'
import { formatField } from './lib/utils'
import flat from 'flat'
import {
  Box,
  JSONViewer,
  SideSheet,
  Paragraph,
  Tabs
} from '@apex/ui'
import './LogSheet.css'

/**
 * LogSheet component.
 */

export default function LogSheet({ log, timezone, formatFields = true, onFilter, onClose }) {
  const [config, setConfig] = useConfig()

  function selectTab({ index }) {
    setConfig({ expandedLogViewTab: index })
  }

  function content({ selected }) {
    switch (selected) {
      case 0:
        return <DetailsView
          log={log}
          timezone={timezone}
          formatFields={formatFields}
          onFilter={onFilter} />
      case 1:
        return <JSONView
          log={log}
          timezone={timezone}
          onFilter={onFilter} />
    }
  }

  return <SideSheet onCloseComplete={onClose}>
    <SideSheet.Title
      text="Viewing Log"
      subtext="Viewing detailed log information." />
    <Tabs
      labels={['Details', 'JSON']}
      selected={config.expandedLogViewTab}
      content={content}
      onSelect={selectTab}
      background={1} />
  </SideSheet>
}

/**
 * JSONView component.
 */

function JSONView({ log, timezone, onFilter, ...props }) {
  const { fields, ...rest } = log
  const value = { ...rest, ...fields }
  return <JSONViewer
    value={value}
    padding={6}
    background={1}
    inset
    flex
    wrap
    {...props} /> 
}

/**
 * DetailsView component.
 */

function DetailsView({ log, timezone, formatFields, onFilter }) {
  const { id, level, message, timestamp, fields } = log

  const date = timezone == 'utc'
    ? timestamp.toUTCString()
    : timestamp.toLocaleString()
  
  const flattened = flat(fields)

  return <Box
    scrollSnapType={isLarge(message) ? "none" : "y mandatory"}
    overflowY="auto"
    scrollbars="none"
    background={1}
    inset
    flex
  >
    <Field name="id" value={id} originalValue={id} onFilter={onFilter} />
    <Field name="level" value={level} originalValue={level} onFilter={onFilter} />
    <Field name="timestamp" value={date} originalValue={date} onFilter={onFilter} />
    <VariableSizeField name="message" value={message} originalValue={message} onFilter={onFilter} />
    {Object.keys(flattened).map(k => {
      const v = formatField(k, flattened[k], true)
      return <VariableSizeField
        key={k}
        name={k}
        value={v}
        originalValue={flattened[k]}
        onFilter={onFilter} />
    })}
  </Box>
}

/**
 * Field component.
 * 
 * originalValue is used to represent the value before formatting,
 * specifically used when copying to the clipboard.
 */

function Field({ name, value, originalValue, onFilter }) {
  return <Box paddingY={2} paddingX={6} borderBottom cursor="pointer" scrollSnapAlign="end" backgroundHover={2}>
    <FieldFilter name={name} value={originalValue} onSelect={onFilter} position="bottom left">
      <Box display="flex" justifyContent="space-between">
        <Paragraph size={1} userSelect="none" marginRight={4}>{name}</Paragraph>
        <Paragraph size={1} muted truncate>{''+value}</Paragraph>
      </Box>
    </FieldFilter>
  </Box>
}

/**
 * LargeField component.
 */

function LargeField({ name, value, onFilter }) {
  return <Box paddingY={2} paddingX={6} borderBottom cursor="pointer" scrollSnapAlign="end" backgroundHover={2}>
    <FieldFilter name={name} value={value} onSelect={onFilter} position="bottom left">
      <Box>
        <Paragraph size={1} userSelect="none">{name}</Paragraph>
        <Box className="large-field-value">
          {value}
        </Box>
      </Box>
    </FieldFilter>
  </Box>
}

/**
 * VariableSizeField component.
 */

function VariableSizeField({ value, ...props }) {
  if (isLarge(value)) {
    return <LargeField value={value} {...props} />
  }
  
  return <Field value={value} {...props} />
}

/**
 * isLarge returns true if the value is a string, and is large.
 */

function isLarge(s) {
  if (typeof s == 'string') {
    return s.length >= 70
  }
  
  return false
}