
import React from 'react'

import {
  Menu,
  Popover,
  EditButton,
} from '@apex/ui'

/**
 * QuerySettingsButton component.
 */

export default function QuerySettingsButton({ themeName, onChangeTheme, timezone, onChangeTimezone, eventsMode, onChangeEventsMode, ...props }) {
  function content() {
    return <Menu full>
      <Menu.OptionsGroup
        title="Display Mode"
        options={[
          { label: 'Compact', value: 'truncate' },
          { label: 'Wrapped', value: 'wrapped' },
          { label: 'Expanded', value: 'expand' }
        ]}
        selected={eventsMode}
        onChange={onChangeEventsMode}
      />
      <Menu.OptionsGroup
        title="Date Format"
        options={[
          { label: 'Local', value: 'local' },
          { label: 'UTC', value: 'utc' }
        ]}
        selected={timezone}
        onChange={onChangeTimezone}
      />
      <Menu.OptionsGroup
        title="Theme"
        options={[
          { label: 'Light', value: 'light' },
          { label: 'Dark', value: 'dark' }
        ]}
        selected={themeName}
        onChange={onChangeTheme}
      />
    </Menu>
  }
  
  return <Popover content={content} position="bottom">
    <EditButton title="Show configuration options." {...props} />
  </Popover>
}