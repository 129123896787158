
import React from 'react'
import {
  Box,
  HBox,
  VBox,
  Paragraph,
  SideSheet,
  Button
} from '@apex/ui'

// TODO: polish and finalize

/**
 * QueryHelp component.
 */

export default function QueryHelp({ onClose }) {
  const desc = <React.Fragment>
    Below are some examples of the kinds of queries you can make, view the <a href="https://apex.sh/docs/logs/querying/" title="Query language documentation" target="_blank">documentation</a> for full details.
  </React.Fragment>
  return <SideSheet onCloseComplete={onClose}>
    <SideSheet.Title
      text="Query Examples"
      subtext={desc} />
    <Box
      overflowY="auto"
      scrollbars="none"
      background={1}
      inset
      flex
    >
      <Content />
    </Box>
  </SideSheet>
}

/**
 * Content component.
 */

function Content() {
  return <React.Fragment>
    <Query help="Show logs with error severity or greater.">
      error
    </Query>

    <Query help="Show events with the text 'login' and 'tj@example.com'.">
      login tj@example.com
    </Query>

    <Query help="Show events with requests to /admin that are not from admin@example.com.">
      /admin !admin@example.com
    </Query>

    <Query help="Show events with any text matching the regular expression.">
      `(tobi|loki|jane)@example\.(io|com)`
    </Query>

    <Query help="Show permission errors writing to /tmp.">
      "Operation not permitted" /tmp/upload-4DFAC3511866
    </Query>

    <Query help="Show 4xx and 5xx responses to any GET requests.">
      GET response.status >= 400
    </Query>

    <Query help="Show 4xx and 5xx responses to any GET requests using explicit fields.">
      request.method = "GET" and response.status >= 400
    </Query>

    <Query help="Show upload requests with a long duration.">
      POST /upload duration > 5s
    </Query>

    <Query help="Show upload requests with a long duration with explicit fields.">
      method = "POST" and path = "/upload" and duration > 5s
    </Query>
    
    <Query help="Show failed file uploads above 100mb.">
      error and message = "Upload" and file.size >= 100mb
    </Query>

    <Query help="Show user logins using a particular domain using wildcard.">
      message = "User Login" and user.email = "*@example.com"
    </Query>

    <Query help="Show user logins using a particular domain using regular expression.">
      message = "User Login" and user.email = `@example\.(io|com)$`
    </Query>

    <Query help="Show responses between a given size.">
      message = "response" and response.size between 1mb and 5mb
    </Query>
    
    <Query help="Show shopping cart checkouts generated outside of the US and CA.">
      message = "Checkout" and user.country not in ("us", "ca")
    </Query>

    <Query help="Show file uploads matching a mime type or extension using wildcards.">
      message = "Upload" and (file.type = "image/png" or file.name = "*.png")
    </Query>
  </React.Fragment>
}

/**
 * Query component.
 */

function Query({ help, children }) {
  return <Box paddingY={4} paddingX={6} borderBottom>
    <Paragraph size={1} userSelect="none" muted>{help}</Paragraph>
    <Box is="code" fontSize={1} marginTop={2} userSelect="all" cursor="pointer">{children}</Box>
  </Box>
}