
import React, { useState } from 'react'
import useFocus from '@apex/lib/hooks/useFocus'
import {
  Form,
  TextInputField,
  TagInputField,
  SelectField,
} from '@apex/ui'

// TODO: validate email and phone numbers, show examples
// TODO: rename AlertNotifications
// TODO: pressing enter clicks Back not Save
// TODO: switch tab on tab, not just select?

/**
 * Kinds of alert notifications.
 */

const kinds = {
  email: {
    name: 'Email',
    component: Email
  },
  pagerduty: {
    name: 'PagerDuty',
    component: PagerDuty
  },
  slack: {
    name: 'Slack',
    component: Slack
  },
  sms: {
    name: 'SMS',
    component: SMS
  },
  webhook: {
    name: 'Webhook',
    component: Webhook
  },
}

/**
 * NotificationForm component.
 */

export default function NotificationForm({ id, name, type = 'email', created_at, ...props }) {
  const [selected, setSelected] = useState(null)
  const C = kinds[selected || type].component
  const focus = useFocus()

  return <React.Fragment>
    <input type="hidden" name="notification[id]" value={id} />
    <input type="hidden" name="notification[created_at]" value={created_at} />

    <Form.Row>
      <TextInputField
        innerRef={focus}
        name="notification[name]"
        label="Name"
        placeholder="Email backend team"
        defaultValue={name}
        autoComplete="none"
        required />
    </Form.Row>

    <Form.Row>
      <SelectField
        label="Type"
        name="notification[type]"
        value={selected || type}
        onChange={e => setSelected(e.target.value)}
      >
        {Object.keys(kinds).map((kind, i) => {
          return <option key={kind} value={kind}>
            {kinds[kind].name}
          </option>
        })}
      </SelectField>
    </Form.Row>

    <C {...props} />
  </React.Fragment> 
}

/**
 * Email component.
 */

function Email({ email_addresses }) {
  return <Form.Row>
    <TagInputField
      name="notification[email_addresses]"
      label="Recipients"
      placeholder="Email..."
      defaultValue={email_addresses}
      required />
  </Form.Row>
}

/**
 * Slack component.
 */

function Slack({ slack_webhook_url, slack_channel }) {
  return <React.Fragment>
    <Form.Row>
      <TextInputField
        name="notification[slack_webhook_url]"
        label="Webhook URL"
        placeholder="https://hooks.slack.com/services/..."
        defaultValue={slack_webhook_url}
        required />
    </Form.Row>
    <Form.Row>
      <TextInputField
        name="notification[slack_channel]"
        label="Channel"
        placeholder="#alerts"
        defaultValue={slack_channel} />
    </Form.Row>
  </React.Fragment>
}


/**
 * PagerDuty component.
 */

function PagerDuty({ pagerduty_service_key }) {
  // TODO: placeholders for PagerDuty fields
  return <React.Fragment>
    <Form.Row>
      <TextInputField
        name="notification[pagerduty_service_key]"
        label="Service Key"
        defaultValue={pagerduty_service_key}
        required />
    </Form.Row>
  </React.Fragment>
}


/**
 * Webhook component.
 */

function Webhook({ webhook_url }) {
  return <React.Fragment>
    <Form.Row>
      <TextInputField
        name="notification[webhook_url]"
        label="Webhook URL"
        placeholder="https://..."
        defaultValue={webhook_url}
        required />
    </Form.Row>
  </React.Fragment>
}


/**
 * SMS component.
 */

function SMS({ sms_numbers }) {
  return <Form.Row>
    <TagInputField
      name="notification[sms_numbers]"
      label="Recipients"
      placeholder="Phone number..."
      defaultValue={sms_numbers}
      required />
  </Form.Row>
}

