
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import usePending from '@apex/lib/hooks/usePending'
import { X, Volume, Volume2 } from 'react-feather'
import {
  Page,
  Button,
  Box,
  Text,
  Strong,
  Loader,
  List,
  EditButton,
  Placeholder,
  toaster
} from '@apex/ui'

/**
 * AlertsPage page.
 */

export default function AlertsPage({ project_id, alerts, actions }) {
  useEffect(_ => {
    actions.loadAlerts({ project_id })
  }, [project_id])

  if (alerts == null) {
    return <React.Fragment>
      <Title project_id={project_id} />
      <Loader />
    </React.Fragment>
  }

  if (alerts.length == 0) {
    return <React.Fragment>
      <Title project_id={project_id} />
      <Placeholder title="No alerts" text="Manage alerts to notify your team when something goes wrong, get started below.">
        <AddButton project_id={project_id} />
      </Placeholder>
    </React.Fragment>
  }

  return <React.Fragment>
    <Title project_id={project_id} />
    <Page.Content.Wrapper>
      <List>
        {alerts.map((a, i) => {
          const last = i == alerts.length - 1

          async function mute() {
            const muted = !a.muted
            await actions.updateAlert({ ...a, muted })
            toaster.success(`Alert ${muted ? 'muted' : 'unmuted'}`)
          }

          async function remove() {
            await actions.removeAlert({ project_id, alert_id: a.id })
          }

          return <List.Item key={a.id} isLast={last}>
            <Alert onMute={mute} onRemove={remove} {...a} />
          </List.Item>
        })}
      </List>
    </Page.Content.Wrapper>
  </React.Fragment>
}

/**
 * Title component.
 */

function Title({ project_id }) {
  return <Page.Title text="Alerts">
    <AddButton project_id={project_id} />
  </Page.Title>
}

/**
 * AddButton component.
 */

function AddButton({ project_id }) {
  return <Button
    is={Link}
    to={`/projects/${project_id}/alerts/add`}
    theme="primary"
  >
    Add alert
  </Button>
}

/**
 * Alert component.
 */

function Alert({ id, project_id, name, description, muted, onMute, onRemove }) {
  const [removePending, remove] = usePending(onRemove)
  const [mutePending, mute] = usePending(onMute)
  const url = `/projects/${project_id}/alerts/${id}/edit`

  return <React.Fragment>
    <Box is={Link} to={url} textDecoration="none">
      <Strong>{name}</Strong>
      <Text marginLeft={2} muted>{description}</Text>
    </Box>
    <Box display="flex" marginLeft="auto">
      <MuteButton pending={mutePending} muted={muted} onClick={mute} />
      <EditButton is={Link} to={url} />
      <Button pending={removePending} theme="minimal" icon={X} onClick={remove} />
    </Box>
  </React.Fragment>
}

/**
 * MuteButton component.
 */

function MuteButton({ muted, ...props }) {
  return <Button
    theme="minimal"
    icon={muted ? Volume : Volume2}
    title={muted ? 'Unmute alert' : 'Mute alert'}
    {...props} />
}
