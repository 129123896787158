
import sleep from '@apex/lib/sleep'
import log from '@apex/lib/log'

/**
 * Log context.
 */

const ctx = log.fields({ scope: 'actions' })

/**
 * Actions class.
 */

export default class Actions {

  /**
   * Initialize.
   */

  constructor({ state = {}, client, render, error }) {
    this.client = client
    this.render = render
    this.state = state
    this.error = (err, options) => {
      console.error(err)
      error(err, options)
    }
  }

  /**
   * Load projects.
   */

  async loadProjects() {
    ctx.info('load projects')
    try {
      const { projects } = await this.client.GetProjects()
      this.state.projects = projects
      this.state.showPaywall = false
      this.render()
    } catch (err) {
      if (err.status == 402) {
        this.state.showPaywall = true
        this.error('Subscription to Apex Logs required', { duration: 8000 })
        this.render()
        return
      }

      this.error(err)
    }
  }

  /**
   * Load projects and their stats.
   */

  async loadProjectsPage() {
    ctx.info('load project stats')
    try {
      this.state.projectStats = this.state.projectStats || {}
      for (let p of this.state.projects) {
        const stats = await this.client.GetProjectStats({ project_id: p.id })
        this.state.projectStats[p.id] = stats
      }
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Add project.
   */

  async addProject(project) {
    ctx.info('add project', project)
    try {
      const { id } = await this.client.AddProject({ project })
      this.loadProjects()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Update project.
   */

  async updateProject(project) {
    ctx.info('update project', project)
    try {
      await this.client.UpdateProject({ project })
      this.loadProjects()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Remove project.
   */

  async removeProject(params) {
    ctx.info('remove project', params)
    try {
      await this.client.RemoveProject(params)
      this.loadProjects()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Add token.
   */

  async addToken(token) {
    ctx.info('add token', token)
    try {
      const { id } = await this.client.AddToken({ token })
      this.loadTokens()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Remove token.
   */

  async removeToken(params) {
    ctx.info('remove token', params)
    try {
      await this.client.RemoveToken(params)
      this.loadTokens()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Load tokens.
   */

  async loadTokens(params) {
    ctx.info('load tokens', params)
    try {
      const { tokens } = await this.client.GetTokens(params)
      this.state.tokens = tokens
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Load alerts.
   */

  async loadAlerts(params, reset = true) {
    ctx.info('load alerts', params)
    try {
      if (reset) {
        this.state.alerts = null
        this.render()
      }

      const { alerts } = await this.client.GetAlerts(params)
      this.state.alerts = alerts
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Add alert.
   */

  async addAlert(alert) {
    ctx.info('add alert', alert)
    try {
      const { id } = await this.client.AddAlert({ alert })
      this.loadAlerts({ project_id: alert.project_id })
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Test alert.
   */

  async testAlert(alert) {
    ctx.info('test alert', alert)
    try {
      await this.client.TestAlert({ alert })
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Update alert.
   */

  async updateAlert(alert) {
    ctx.info('update alert', alert)
    try {
      await this.client.UpdateAlert({ alert })
      this.loadAlerts({ project_id: alert.project_id }, false)
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Remove alert.
   */

  async removeAlert(params) {
    ctx.info('remove alert', params)
    try {
      await this.client.RemoveAlert(params)
      this.loadAlerts({ project_id: params.project_id }, false)
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Add notification.
   */

  async addNotification(notification) {
    ctx.info('add notification', notification)
    try {
      const { id } = await this.client.AddNotification({ notification })
      this.loadNotifications({ project_id: notification.project_id })
      return { id }
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Update notification.
   */

  async updateNotification(notification) {
    ctx.info('update notification', notification)
    try {
      await this.client.UpdateNotification({ notification })
      this.loadNotifications({ project_id: notification.project_id })
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Remove notification.
   */

  async removeNotification(params) {
    ctx.info('remove notification', params)
    try {
      await this.client.RemoveNotification(params)
      this.loadNotifications({ project_id: params.project_id }, false)
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Load notifications.
   */

  async loadNotifications(params, reset = true) {
    ctx.info('load notifications', params)
    try {
      if (reset) {
        this.state.notifications = null
        this.render()
      }

      const { notifications } = await this.client.GetNotifications(params)
      this.state.notifications = notifications
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Add search.
   */

  async addSearch(search) {
    ctx.info('add search', search)
    try {
      const { id } = await this.client.AddSearch({ search })
      this.loadSearches({ project_id: search.project_id })
      return { id }
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Update search.
   */

  async updateSearch(search) {
    ctx.info('update search', search)
    try {
      await this.client.UpdateSearch({ search })
      this.loadSearches({ project_id: search.project_id })
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Remove search.
   */

  async removeSearch(params) {
    ctx.info('remove search', params)
    try {
      await this.client.RemoveSearch(params)
      this.loadSearches({ project_id: params.project_id }, false)
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Load searches.
   */

  async loadSearches(params) {
    ctx.info('load searches', params)
    try {
      const { searches } = await this.client.GetSearches(params)
      this.state.searches = searches
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Perform a search.
   */

  async search(params) {
    ctx.info('search', params)
    try {
      this.state.events = { pending: true }
      this.render()

      const start = new Date
      const { events, stats } = await this.client.Search(params)
      logQueryCost('search', stats)

      const normalizedResults = events.map(normalizeLog)

      this.state.events = {
        pending: false,
        results: normalizedResults,
        prevResults: normalizedResults,
        duration: new Date - start,
        params: params,
        stats
      }
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Perform a search for additional events.
   */

  async searchMore() {
    try {
      const { params, prevResults } = this.state.events
      const { start, stop } = searchMoreRange({ results: prevResults, ...params })

      ctx.info('search more', { start, stop })
      this.state.events.morePending = true
      this.render()

      const newParams = { ...params, start, stop }
      const { events, stats } = await this.client.Search(newParams)
      logQueryCost('search', stats)

      const newPrevResults = events.map(normalizeLog)
      this.state.events.stats = stats
      this.state.events.morePending = false
      this.state.events.prevResults = newPrevResults
      this.state.events.results = this.state.events.results.concat(newPrevResults)
      this.state.events.params = newParams
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Reset events.
   */
  
  resetEvents() {
    this.state.events = {}
    this.state.timeseries = null
    this.state.fields = null
    this.render()
  }

  /**
   * Load level timeseries for a query.
   */

  async loadLevelTimeseries(params) {
    ctx.info('load level timeseries', params)
    try {
      this.state.timeseries = { pending: true }
      this.render()

      const { points, stats } = await this.client.GetLevelTimeseries(params)
      logQueryCost('get_level_timeseries', stats)
      this.state.timeseries = { pending: false, points }
      this.render()
    } catch (err) {
      this.error(err)
    }
  }


  /**
   * Load timeseries for a query.
   */

  async loadTimeseries(params) {
    ctx.info('load timeseries', params)
    try {
      this.state.timeseries = { pending: true }
      this.render()

      const { points, stats } = await this.client.GetTimeseries(params)
      logQueryCost('get_timeseries', stats)
      this.state.timeseries = { pending: false, points }
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Load discovered fields.
   */

  async loadDiscoveredFields(params) {
    ctx.info('load discovered fields', params)
    try {
      this.state.fields = null
      this.render()

      const { fields, stats } = await this.client.GetDiscoveredFields(params)
      logQueryCost('get_discovered_fields', stats)
      this.state.fields = fields
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Load field stats.
   */

  async loadFieldStats({ type, ...params }) {
    ctx.info('load field stats', { type, ...params })
    switch (type) {
      case 'string':
        this.loadStringFieldStats(params)
        break;
      case 'number':
        this.loadNumericFieldStats(params)
        break;
      case 'boolean':
        this.loadBooleanFieldStats(params)
        break;
    }
  }

  /**
   * Load boolean field stats.
   */

  async loadBooleanFieldStats(params) {
    try {
      const { field } = params
      this.state.fieldStats = this.state.fieldStats || {}
      delete this.state.fieldStats[field]
      
      const { values, stats } = await this.client.GetBooleanFieldStats(params)
      logQueryCost('get_boolean_field_stats', stats)
      this.state.fieldStats[field] = values
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Load string field stats.
   */

  async loadStringFieldStats(params) {
    try {
      const { field } = params
      this.state.fieldStats = this.state.fieldStats || {}
      delete this.state.fieldStats[field]
      
      const { values, stats } = await this.client.GetStringFieldStats(params)
      logQueryCost('get_string_field_stats', stats)
      this.state.fieldStats[field] = values
      this.render()
    } catch (err) {
      this.error(err)
    }
  }

  /**
   * Load numeric field stats.
   */

  async loadNumericFieldStats(params) {
    try {
      const { field } = params
      this.state.fieldStats = this.state.fieldStats || {}
      delete this.state.fieldStats[field]
      
      const res = await this.client.GetNumericFieldStats(params)
      logQueryCost('get_numeric_field_stats', res.stats)
      this.state.fieldStats[field] = res
      this.render()
    } catch (err) {
      this.error(err)
    }
  }
}

/**
 * searchMoreRange .
 */

function searchMoreRange({ results, start, stop, limit }) {
  // we may have more in the current range,
  // continue query relative to the last result
  if (results.length >= limit) {
    return {
      stop: results[results.length - 1].timestamp,
      start
    }
  }
  
  // we covered everything in the range, need a new one
  const day = 86400000
  return {
    stop: start,
    start: new Date(start - day)
  }
}

/**
 * normalizeLog result.
 */

function normalizeLog(log) {
  return {
    ...log,
    timestamp: new Date(log.timestamp)
  }
}

/**
 * byTimestamp sort.
 */

function byTimestamp(a, b) {
  return b.timestamp - a.timestamp
}

/**
 * logQueryCost logs the query cost.
 */

function logQueryCost(label, stats) {
  const { total_bytes_billed: bytes } = stats
  ctx.info(label, {
    cost: queryCost(bytes),
    mb: bytes / 1024 / 1024,
  })
}

/**
 * queryCost returns the cost estimate of query bytes processed.
 */

function queryCost(size) {
  return (size / 1e+12) * 5
}