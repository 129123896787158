
import React, { useState } from 'react'
import './Fields.css'
import { formatNumber } from './lib/utils'
import {
  Box,
  VBox,
  Loader,
  SearchInput,
  Heading,
  Paragraph,
  Spacer,
  Title,
} from '@apex/ui'

/**
 * Fields component.
 */

export default Fields

/**
 * Fields component.
 */

function Fields({ fields = [], onFieldClick, ...props }) {
  const [filter, setFilter] = useState("")

  function content() {
    // loading
    if (fields == null) {
      return <Loader />
    }

    // filter
    fields = fields
      .filter(fieldTypeFilter('object'))
      .filter(fieldFilter(filter))
    
    // filter duplicates
    fields = unique(fields)

    // no fields
    if (fields.length == 0) {
      return <List>
        <VBox align="center">
          <Paragraph muted>No matching fields.</Paragraph>
        </VBox>
      </List>
    }

    // fields
    return <List animate>
      {fields.map(f => {
        return <Field
          key={f.name + f.type}
          {...f}
          onClick={e => onFieldClick(f)} />
      })}
    </List>
  }

  return <VBox
    className="Fields"
    paddingTop={8}
    paddingBottom={4}
    background={0}
    flex={false}
    width={304}
    borderLeft
    shadowLeft
    {...props}
  >
    <Box marginBottom={6} paddingX={8}>
      <Title text="Fields" subtext="Discovered fields for the query." />
      <Spacer y={4} />
      <SearchInput
        placeholder="Filter"
        value={filter}
        spellCheck={false}
        onFocus={_ => setFilter("")}
        onChange={e => setFilter(e.target.value)} />
    </Box>
    {content()}
  </VBox>
}

/**
 * List component.
 */

function List({ children }) {
  return <Box
    className="list"
    animate="show"
    paddingX={6}
    overflowY="auto"
    flex
  >
    {children}
  </Box>
}

/**
 * Field component.
 */

function Field({ name, type, count, percent, ...props }) {
  const p = formatPercent(percent)
  
  if (name.length > 20) {
    props.title = name
  }

  return <div className="Field" {...props}>
    <Heading size={1} truncate>{name}</Heading>
    <Box fontSize={1} className="type">{type}</Box>
    <Box fontSize={1} muted className="percent" title={`${formatNumber(count)} events`}>{p}</Box>
  </div>
}

/**
 * unique filters out duplicates.
 */

function unique(fields) {
  const m = new Map
  return fields.filter(f => {
    const k = f.name + f.type
    if (m.has(k)) return false
    m.set(k, true)
    return true
  })
}

/**
 * fieldTypeFilter filters out the given type.
 */

function fieldTypeFilter(type) {
  return f => f.type != type
}

/**
 * fieldFilter filters fields by name.
 */

function fieldFilter(filter) {
  return f => filter
    ? f.name.includes(filter)
    : true
}

/**
 * formatPercent returns a formatted percentage.
 */

function formatPercent(n) {
  return `${n * 100 | 0}%`
}
