
import React, { useEffect, useState } from 'react'
import usePending from '@apex/lib/hooks/usePending'
import useRedirect from './lib/useRedirect'
import NotificationForm from './NotificationForm'
import {
  Button,
  Form,
  Loader,
  Page,
  toaster
} from '@apex/ui'

/**
 * NotificationPage component.
 */

export default function NotificationPage({ project_id, notifications, notification_id, actions, ...props }) {
  const [redirect, saved] = useRedirect(`/projects/${project_id}/notifications`)
  const isNew = notification_id == null

  // load notifications
  useEffect(_ => {
    actions.loadNotifications({ project_id })
  }, [project_id])

  // pending notifications
  if (notifications == null) {
    return <React.Fragment>
      <Page.Title text={isNew ? 'Add notification' : 'Edit notification'} />
      <Loader />
    </React.Fragment>
  }

  // existing notification
  const notification = notifications.find(n => n.id == notification_id)

  // TODO: handle missing notification and project

  async function onSubmit(notification) {
    if (isNew) {
      await actions.addNotification(notification)
    } else {
      await actions.updateNotification(notification)
    }

    toaster.success('Saved notification')
    saved()
  }

  return <React.Fragment>
    {redirect}
    <Page.Title text={isNew ? 'Add notification' : 'Edit notification'} />
    <Page.Content.Wrapper>
      <Notification
        notification={notification || {}}
        project_id={project_id}
        onSubmit={onSubmit} />
    </Page.Content.Wrapper>
  </React.Fragment>
}

/**
 * Notification component.
 */

function Notification({ notification, project_id, onSubmit }) {
  const [pending, submit] = usePending(({ notification }) => {
    return onSubmit({
      ...notification,
      project_id,
      email_addresses: maybeSplit(notification.email_addresses),
      sms_numbers: maybeSplit(notification.sms_numbers)
    })
  }) 

  return <Form onSubmit={submit}>
    <NotificationForm {...notification} />
    <Form.Actions>
      <Button theme="primary" pending={pending}>Save notification</Button>
    </Form.Actions>
  </Form>
}

/**
 * Maybe split returns the split string or null.
 */

function maybeSplit(s) {
  return s ? s.split(',') : null
}