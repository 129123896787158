
import React, { useEffect, useState } from 'react'
import usePending from '@apex/lib/hooks/usePending'
import useRedirect from './lib/useRedirect'
import TokenForm from './TokenForm'
import {
  Button,
  Form,
  Loader,
  Page, 
  toaster
} from '@apex/ui'


/**
 * TokenPage component.
 */

export default function TokenPage({ actions, ...props }) {
  const [redirect, saved] = useRedirect(`/tokens`)

  async function onSubmit(token) {
    await actions.addToken(token)
    toaster.success('Saved token')
    saved()
  }

  return <React.Fragment>
    {redirect}
    <Page.Title text="Add token" />
    <Page.Content.Wrapper>
      <Token onSubmit={onSubmit} />
    </Page.Content.Wrapper>
  </React.Fragment>
}

/**
 * Token component.
 */

function Token({ onSubmit }) {
  const [pending, submit] = usePending(({ description, ...scopes }) => {
    const token = {
      description,
      scopes: []
    }

    for (let scope in scopes) {
      if (scope != "") {
        token.scopes.push(scope)
      }
    }

    return onSubmit(token)
  }) 

  return <Form onSubmit={submit}>
    <TokenForm />
    <Form.Actions>
      <Button theme="primary" pending={pending}>Save token</Button>
    </Form.Actions>
  </Form>
}
