
import React, { useState } from 'react'
import { Redirect } from 'react-router' 

/**
 * Use redirect hook returns a redirect component
 * and a function which should be invoked when the
 * redirect is to be enabled.
 */

export default function useRedirect(to) {
  const [redirecting, setRedirecting] = useState(false)
  const fn = _ => setRedirecting(true)
  const el = redirecting && <Redirect to={to} />
  return [el, fn]
}