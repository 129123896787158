
import React from 'react'
import { getRelativeTimeOptions } from './lib/utils'

import {
  Menu,
  Popover,
  Button
} from '@apex/ui'

/**
 * Relative time options.
 */

const relativeTimeOptions = getRelativeTimeOptions()

/**
 * TimeRangeButton component.
 */

export default function TimeRangeButton({ selected, onChange, ...props }) {
  function content() {
    return <Menu full>
      <Menu.OptionsGroup
        title="Time Range"
        options={relativeTimeOptions}
        selected={selected.value}
        onChange={v => onChange(relativeTimeOptions.find(o => o.value == v))}
      />
    </Menu>
  }
  
  return <Popover content={content} position="bottom">
    <Button is="a" {...props}>
      {selected.label}
    </Button>
  </Popover>
}