
import React from 'react'
import ReactDOM from 'react-dom'
import { App, Login, toaster } from '@apex/ui'
import Application from './Application'
import Client from './lib/Client'
import Actions from './lib/Actions'
import TeamClient from '@apex/lib/Client'
import TeamActions from '@apex/lib/Actions'
import config from '@apex/lib/config'
import * as auth from '@apex/lib/auth'
import useTheme from '@apex/lib/hooks/useTheme'

/**
 * Root element.
 */

const root = document.getElementById('root')

/**
 * error handler.
 */

function error(err, options = {}) {
  toaster.danger(err.message || err, { duration: 0, unique: true, ...options })
}

/**
 * Auth from cookie.
 */

const authToken = auth.get()

/**
 * Team client.
 */

const teamClient = new TeamClient({
  authToken,
  url: config.api.url
})

/**
 * Team actions.
 */

const teamActions = new TeamActions({
  state: {
    user: authToken ? auth.parse(authToken) : null
  },
  client: teamClient,
  render,
  error
})

/**
 * App client.
 */

const client = new Client({
  authToken,
  url: process.env.NODE_ENV == 'production'
    ? window.location.origin
    : 'http://logs.test:5001'
})

/**
 * App actions available.
 */

const actions = new Actions({
  state: {
    data: null
  },
  client,
  render,
  error
})

/**
 * Application URL.
 */

const applicationURL = window.location.origin

/**
 * render the app.
 */

function render() {
  client.authToken = teamClient.authToken
  ReactDOM.render(<Root />, root)
}

/**
 * Root component.
 */

function Root() {
  const [theme, setTheme] = useTheme()
  return <App>
    {teamActions.state.user
      ? <Application
          actions={actions}
          teamActions={teamActions}
          applicationName="Apex Logs"
          applicationURL={applicationURL}
          theme={theme}
          onChangeTheme={setTheme}
          {...actions.state}
          {...teamActions.state} />
      : <Login
          onLoginWithCode={e => teamActions.loginWithCode(e)}
          onLoginWithToken={e => teamActions.loginWithToken(e)}
          onLogin={e => teamActions.login(e)} />}
  </App>
}

render()

