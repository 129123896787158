
import copyToClipboard from 'clipboard-lite'
import { toaster } from '@apex/ui'

/**
 * copy text to clipboard and display a toast.
 */

export default function copy(text) {
  copyToClipboard(text)
  toaster.success('Copied to clipboard')
}