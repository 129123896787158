
/**
 * Levels supported.
 */

import { statusCodes } from './http'
import bytes from 'bytes'
import ms from "ms"

export const levels = [
  "debug",
	"info",
	"notice",
	"warning",
	"error",
	"critical",
	"alert",
	"emergency",
]

/**
 * isJSON returns true if the string looks like JSON.
 */

export function isJSON(s = '') {
  s = s.trim()
  return s[0] == '{' && s[s.length - 1] == '}'
}

/**
 * getRelativeTimeOptions returns relative time options.
 */

export function getRelativeTimeOptions() {
  const m = 1000 * 60
  const h = m * 60
  return [
    { label: 'Past 5 minutes', value: 'past_5_minutes', milliseconds: m * 5 },
    { label: 'Past 15 minutes', value: 'past_15_minutes', milliseconds: m * 15 },
    { label: 'Past 30 minutes', value: 'past_30_minutes', milliseconds: m * 30 },
    { label: 'Past hour', value: 'past_hour', milliseconds: h },
    { label: 'Past 6 hours', value: 'past_6_hours', milliseconds: h * 6 },
    { label: 'Past 12 hours', value: 'past_12_hours', milliseconds: h * 12 },
    { label: 'Past day', value: 'past_day', milliseconds: h * 24 },
    { label: 'Past 3 days', value: 'past_3_days', milliseconds: h * 24 * 3 },
    { label: 'Past week', value: 'past_week', milliseconds: h * 24 * 7 },
    { label: 'Past month', value: 'past_month', milliseconds: h * 24 * 30 },
  ]
}

/**
 * formatDate returns a formatted date.
 */

export function formatDate({ timestamp, timezone }) {
  if (timezone == 'utc') {
    return timestamp.toLocaleString(undefined, {
      timeZone: 'UTC',
      hour12: false,
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    })
  }

  return timestamp.toLocaleString(undefined, {
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
}


/**
 * formatNumber returns a formatted number.
 */

export function formatNumber(n) {
  return n.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })
}

/**
 * truncate returns a truncated string if it exceeds max characters.
 */

export function truncate(s, max = 156) {
  return s.length < max
    ? s
    : s.slice(0, max) + '…'
}

/**
 * Pluralize a string.
 */

export function plural(count, suffix = 's') {
  return count == 1 
    ? ''
    : suffix
}

/**
* unique returns unique values using the given hashing function.
*/

export function unique(arr, hash) {
 const m = {}
 const r = []

 for (let i = 0; i < arr.length; i++) {
   const v = arr[i]
   const h = hash(v)
   if (m[h]) continue
   m[h] = true
   r.push(v)
 }

 return r
}

/**
 * formatField returns a formatted field value.
 */

export function formatField(name, value, verbose = false) {
  const last = name.split('.').pop()

  // durations
  if (last == 'duration' && typeof value == 'number') {
    return ms(value)
  }
  
  // byte sizes
  if ((last == 'size' || last == 'bytes') && typeof value == 'number') {
    if (value < 1000) {
      return value
    }
    return bytes(value)   
  }

  // status codes
  if (verbose && last == 'status' && typeof value == 'number') {
    return `${value} ${statusCodes[value]}`
  }

  return value
}