
import React, { useRef, useState } from 'react'
import useResize from 'react-resize-observer-hook'
import { plural } from './lib/utils'
import BarChart from './BarChart'
import { Box } from '@apex/ui'
import './Timeseries.css'


/**
 * Timeseries component.
 */

export default function Timeseries({ series, timezone }) {
  const ref = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [bin, setBin] = useState(null)
  const pending = series == null || series.pending == true

  useResize(ref, setDimensions)

  const data = _ => series.points.map(p => {
    return {
      bin: new Date(p.timestamp),
      value: p.count
    }
  })

  const chart = pending || <BarChart
    width={dimensions.width}
    height={dimensions.height}
    barPadding={1}
    axis={false}
    type="rect"
    color={['HSL(233, 88%, 68%)', '#8692FE']}
    mouseover={setBin}
    mouseout={e => setBin(null)}
    data={data()} />
  
  return <Box
    className="Timeseries"
    innerRef={ref}
    borderBottom
    shadowBottom
    height={56}
    flexShrink={0}
  >
    {chart}
    {bin && <Stats timestamp={bin.bin} count={bin.value} timezone={timezone} />}
  </Box>
}

/**
 * Stats component.
 */

function Stats({ timezone, timestamp, count }) {
  const date = timezone == 'utc'
    ? timestamp.toUTCString()
    : timestamp.toLocaleString()

  return <div className="stats">
    {count} event{plural(count)} near {date}
  </div>
}