
import React, { memo } from 'react'
import { MoreVertical, Plus, Minus, Eye, Copy } from 'react-feather'
import { formatDate, formatField } from './lib/utils'
import FieldFilter from './FieldFilter'
import flat from 'flat'
import './Events.css'
import {
  Button,
  Loader,
  Popover,
  Menu,
  Placeholder,
  Box
} from '@apex/ui'

/**
 * Events component.
 */

export default Events

/**
 * Events component.
 * 
 * - mode: "truncate", "expand"
 */

function Events({ timezone, results, stats, pending, highlight, mode, formatFields = true, onExpand, onShowHelp, onFilter, children, ...props }) {
  if (pending) {
    return <Loader  {...props} />
  }

  if (pending == null) {
    return <Placeholder
      title="Search"
      text={`Type a query in the search input above. Check out some examples to get started.`}>
        <Button theme="primary" onClick={onShowHelp}>View examples</Button>
      </Placeholder>
  }
  
  if (results.length == 0) {
    return <Placeholder
      title="No results"
      text="The current search query and time range used yielded no results, try another search." />
  }

  return <Box className={`Events ${mode}`} scrollbars="none">
    {results.map((event, index) => {
      return <Event
        key={event.id}
        even={index % 2 == 0}
        highlight={index == highlight}
        timezone={timezone}
        onExpand={e => onExpand({ event, index })}
        formatFields={formatFields}
        onFilter={onFilter}
        {...event} />
    })}
    {children}
  </Box>
}

/**
 * Event component.
 */

const Event = memo(function Event({ id, level, message, fields, timestamp, even, timezone, highlight, formatFields, onFilter, onExpand, ...props }) {
  const normalizedFields = flat(fields)
  const event = { id, level, message, fields }
  return <div className={`Event ${level} ${even ? 'even' : ''} ${highlight ? 'highlight' : ''}`} onClick={onExpand} {...props}>
    <EventMenu onExpand={onExpand} onFilter={onFilter} event={event} />
    <Timestamp timestamp={timestamp} timezone={timezone} />
    <Level level={level} />
    <div className="details">
      {message != '' && <Message message={message} />}
      <Fields format={formatFields} fields={normalizedFields} onFilter={onFilter} />
    </div>
  </div>
}, (a, b) => {
  return a.id == b.id
    && a.timezone == b.timezone
    && a.highlight == b.highlight
    && a.formatFields == b.formatFields
})

/**
 * EventMenu component.
 */

function EventMenu({ event, onExpand, onFilter }) {
  function content({ close }) {
    function filter(op) {
      return e => {
        close()
        const value = op == 'copy'
          ? JSON.stringify(event, null, 2)
          : event.message
        onFilter({ op, name: 'message', value })
      }
    }

    function expand() {
      close()
      onExpand()
    }

    return <Menu full small>
      <Menu.Item icon={Eye} onSelect={expand}>View details</Menu.Item>
      <Menu.Item icon={Plus} onSelect={filter('show')}>Show matching</Menu.Item>
      <Menu.Item icon={Minus} onSelect={filter('hide')}>Hide matching</Menu.Item>
      <Menu.Item icon={Copy} onSelect={filter('copy')}>Copy to clipboard</Menu.Item>
    </Menu>
  }

  return <Popover content={content} position="bottom left">
    <Button theme="minimal" icon={MoreVertical} size="small" />
  </Popover>
}

/**
 * Level component.
 */

function Level({ level }) {
  return <div className="level">
    {level.slice(0, 4)}
  </div>
}

/**
 * Timestamp component.
 */

function Timestamp({ timestamp, timezone }) {
  return <div className="timestamp">
    {formatDate({ timestamp, timezone })}
  </div>
}

/**
 * Message component.
 */

function Message({ message }) {
  return <div className="message">
    {message}
  </div>
}

/**
 * Fields component.
 */

function Fields({ fields, format, onFilter }) {
  return <div className="fields">
    {Object.keys(fields).map(name => {
      const value = fields[name]
      return <EventField key={name} name={name} value={value} format={format} onFilter={onFilter} />
    })}
  </div>
}

/**
 * EventField component.
 */

function EventField({ name, value, format, onFilter }) {
  const originalValue = value

  if (format) {
    value = formatField(name, value)
  }

  return <div className="field" title={originalValue === value ? '' : ''+originalValue}>
    <FieldFilter name={name} value={originalValue} onSelect={onFilter}>
      <span className="name">{name}</span>
    </FieldFilter>
    <span className="value">{''+value}</span>
  </div>
}
