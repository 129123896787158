
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import usePending from '@apex/lib/hooks/usePending'
import humanize from 'humanize-plus'
import {
  Page,
  Box,
  ConfirmationDialog,
  RemoveButton,
  EditButton,
  Placeholder,
  Button,
  List,
  Text,
  Strong,
  Loader
} from '@apex/ui'

/**
 * ProjectsPage page.
 */

export default function ProjectsPage({ projects, projectStats = {}, actions }) {
  useEffect(_ => {
    actions.loadProjectsPage()    
  }, [])

  if (projects == null) {
    return <React.Fragment>
      <Title />
      <Loader />
    </React.Fragment>
  }
  
  if (projects.length == 0) {
    return <React.Fragment>
      <Title />
      <Placeholder title="No projects" text="Projects contain log events and alerts for a application or environment.">
        <AddButton />
      </Placeholder>
    </React.Fragment>
  }

  return <React.Fragment>
    <Title />
    <Page.Content.Wrapper>
      <List>
        {projects.map((p, i) => {
          const last = i == projects.length - 1
          
          async function remove() {
            await actions.removeProject({ project_id: p.id })
          }

          return <List.Item key={p.id} isLast={last}>
            <Project onRemove={remove} stats={projectStats[p.id]} {...p} />
          </List.Item>
        })}
      </List>
    </Page.Content.Wrapper>
  </React.Fragment>
}

/**
 * Title component.
 */

function Title() {
  return <Page.Title text="Projects">
    <AddButton />
  </Page.Title>
}

/**
 * AddButton component.
 */

function AddButton() {
  return <Button
    is={Link}
    to="/projects/add"
    theme="primary"
  >
    Add project
  </Button>
}

/**
 * Project component.
 */

function Project({ id, name, description, created_at, stats, onRemove }) {
  const [removePending, remove] = usePending(onRemove)
  const [confirming, setConfirming] = useState(false)

  const dialog = confirming && <ConfirmationDialog
    text={`Are you sure you want to remove "${name}" and lose all log events?`}
    onCloseComplete={_ => setConfirming(false)}
    onConfirm={_ => remove()}
    confirmLabel="Remove"
    theme="danger"
  />

  return <React.Fragment>
    {dialog}
    <Box
      is={Link}
      to={`/projects/${id}`}
      textDecoration="none"
    >
      <Strong>{name}</Strong>
      <Text marginLeft={2} muted>{description}</Text>
    </Box>
    <Box display="flex" marginLeft="auto" alignItems="center">
      {stats && <ProjectStats {...stats} />}
      <EditButton is={Link} to={`/projects/${id}/edit`} />
      <RemoveButton pending={removePending} onClick={e => setConfirming(true)} />
    </Box>
  </React.Fragment>
}

/**
 * ProjectStats component.
 */

function ProjectStats({ bytes_total, events_total }) {
  return <Box marginRight={8}>
    <Text>{humanize.fileSize(bytes_total)}</Text>{' '}
    <Text>{humanize.compactInteger(events_total, 1)} events</Text>
  </Box>
}