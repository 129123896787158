
import React, { useState } from 'react'
import useFocus from '@apex/lib/hooks/useFocus'
import { Star, Edit2 } from 'react-feather' 
import useConfig from './lib/useConfig'
import SheetPlaceholder from './SheetPlaceholder'
import './QueryHistory.css'
import {
  Box,
  Button,
  Tabs,
  RemoveButton,
  Text,
  SideSheet,
  Form,
  TextInputField
} from '@apex/ui'

/**
 * QueryHistory component.
 */

export default function QueryHistory({ onSelect, onClose, ...props }) {
  const [editing, setEditing] = useState(null)
  const { onUpdate } = props

  function content({ close }) {
    function submit(e) {
      setEditing(null)
      onUpdate(e)
    }

    if (editing) {
      return <Box background={1} padding={6} flex>
        <EditForm
          {...editing}
          onCancel={_ => setEditing(null)}
          onSubmit={submit} />
      </Box>
    }

    return <History
      onSelect={e => (close(), onSelect(e))}
      onEdit={e => setEditing(e)}
      {...props} />
  }

  return <SideSheet content={content} onCloseComplete={onClose}>
    <SideSheet.Title
      text="Searches"
      subtext="View recent and saved searches." />
  </SideSheet>
}

/**
 * EditForm component.
 */

function EditForm({ id, project_id, name, query, created_at, onCancel, ...props }) {
  function cancel(e) {
    e.preventDefault()
    onCancel(e)
  }

  const focus = useFocus()

  return <Form {...props}>
    <input type="hidden" name="id" value={id} />
    <input type="hidden" name="project_id" value={project_id} />
    <input type="hidden" name="created_at" value={created_at} />
    <Form.Row>
      <TextInputField
        name="name"
        label="Name"
        hint="The saved search name."
        autoComplete="none"
        required
        innerRef={focus}
        defaultValue={name == query ? '' : name} />
    </Form.Row>
    <Form.Row>
      <TextInputField
        name="query"
        label="Query"
        hint="The saved search query text."
        autoComplete="none"
        required
        defaultValue={query} />
    </Form.Row>
    <Form.Actions>
      <Button is="a" onClick={cancel}>Cancel</Button>
      <Button theme="primary">Save</Button>
    </Form.Actions>
  </Form>
}

/**
 * History component.
 */

function History({ saved = [], recent = [], onSelect, onAdd, onEdit, onRemove }) {
  const [config, setConfig] = useConfig()

  function selectTab({ index }) {
    setConfig({ queryHistoryTab: index })
  }

  function save(query) {
    selectTab({ index: 0 })
    onAdd(query)
  }

  function content({ selected }) {
    switch (selected) {
      case 0:
        return <Saved
          queries={saved}
          onSelect={onSelect}
          onRemove={onRemove}
          onEdit={onEdit} />
      case 1:
        return <Recent
          queries={recent}
          onSelect={onSelect}
          onAdd={save} />
    }
  }

  return <Tabs
    labels={['Saved', 'Recent']}
    selected={config.queryHistoryTab}
    content={content}
    onSelect={selectTab}
    background={1} />
}

/**
 * Items component.
 */

function Items({ children }) {
  return <Box
    className="QueryHistory"
    scrollSnapType="y mandatory"
    overflowY="auto"
    overflowX="hidden"
    scrollbars="none"
    background={1}
    inset
    flex
  >
    {children}
  </Box>
}

/**
 * Item component.
 */

function Item({ children, ...props }) {
  return <Box
    className="item"
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    paddingX={6}
    cursor="pointer"
    height={40}
    borderBottom
    backgroundHover={2}
    {...props}
  >
    {children}
  </Box>
}

/**
 * Recent component.
 */

function Recent({ queries, onSelect, onAdd }) {
  return <Items>
    {queries.length == 0 && <SheetPlaceholder text="No recent searches." />}
    {queries.map(query => {
      return <RecentItem
        key={query}
        query={query}
        onClick={_ => onSelect({ query })}
        onAdd={_ => onAdd({ query })} />
    })}
  </Items>
}

/**
 * RecentItem component.
 */

function RecentItem({ query, onClick, onAdd }) {
  function add(e) {
    e.stopPropagation()
    onAdd(e)
  }

  return <Item onClick={onClick}>
    <Text size={0} truncate>{query}</Text>
    <Button
      title="Save this query."
      theme="minimal"
      size="small"
      icon={Star}
      onClick={add} />
  </Item>
}

/**
 * Saved component.
 */

function Saved({ queries, onSelect, onRemove, onEdit }) {
  return <Items>
    {queries.length == 0 && <SheetPlaceholder text="No saved searches." />}
    {queries.sort(byName).map(v => {
      return <SavedItem
        key={v.id}
        name={v.name}
        query={v.query}
        onEdit={_ => onEdit(v)}
        onClick={_ => onSelect(v)}
        onRemove={_ => onRemove(v)} />
    })}
  </Items>
}

/**
 * SavedItem component.
 */

function SavedItem({ name, query, onClick, onRemove, onEdit }) {
  return <Item onClick={onClick}>
    <Text size={0} truncate>{name || query}</Text>
    
    <Box display="flex" marginLeft={2}>
      <Button
        title="Edit this query."
        theme="minimal"
        size="small"
        icon={Edit2}
        onClick={e => (e.stopPropagation(), onEdit(e))} />
      
      <RemoveButton
        title="Removed saved query."
        size="small"
        onClick={e => (e.stopPropagation(), onRemove(e))} />
    </Box>
  </Item>
}

/**
 * byName sort.
 */

function byName(a, b) {
  return a.name.localeCompare(b.name)
}
