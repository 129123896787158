
import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect, withRouter } from 'react-router'
import useConfig from './lib/useConfig'
import { Navigation, ProjectNavigation } from './Navigation'
import NotificationsPage from './NotificationsPage'
import NotificationPage from './NotificationPage'
import ProjectEditPage from './ProjectEditPage'
import ProjectsPage from './ProjectsPage'
import ProjectPage from './ProjectPage'
import AlertsPage from './AlertsPage'
import AlertPage from './AlertPage'
import TokensPage from './TokensPage'
import TokenPage from './TokenPage'

import {
  Page,
  Loader,
  TeamNavigation,
  TeamRoutes,
  TeamSelect
} from '@apex/ui'

/**
 * Application component.
 */

export default withRouter(Application)

/**
 * Application component.
 */

function Application(props) {
  let { projects, teamActions, actions, user, team, teams, showPaywall, location } = props
  const [config, setConfig] = useConfig()
  const expanded = config.sidebarExpanded

  async function loadTeams() {
    await teamActions.loadTeams()
    teamActions.initializeActiveTeam()
  }

  useEffect(_ => {
    loadTeams()
    teamActions.loadAccount()
    actions.loadProjects()
  }, [user.id])

  if (team == null) {
    return <Loader />
  }

  function content() {
    // redirect to subscriptions
    if (showPaywall) {
      if (location.pathname == "/team/subscriptions") {
        return null
      }
      return <Redirect to="/team/subscriptions" />
    }

    // don't show loading indication on team routes
    const teamRoute = location.pathname.startsWith('/team/')
      || location.pathname.startsWith('/teams')
      || location.pathname.startsWith('/account/')
    
    if (teamRoute) {
      return null
    }

    if (projects == null) {
      return <Loader />
    }

    return <AppRoutes {...props} actions={actions} />  
  }

  return <Page>
    <Page.Sidebar user={user} expanded={expanded} onClickExpand={_ => setConfig({ sidebarExpanded: !expanded })}>
      <TeamSelect user={user} team={team} teams={teams} onChange={team => teamActions.changeTeam(team)} />
      <Switch>
        <Route path="/projects/add" render={r => <Navigation /> } /> 
        <Route path="/projects/:project_id" render={r => <ProjectNavigation project_id={r.match.params.project_id} /> } />
        <Route path="/teams" render={r => <TeamNavigation back /> } />
        <Route path="/team" render={r => <TeamNavigation back /> } />
        <Route path="*" render={r => <Navigation /> } /> 
      </Switch>
    </Page.Sidebar>
    
    <Page.Content>
      {content()}
      <TeamRoutes
        {...props}
        onSubscribe={_ => actions.loadProjects()}
        actions={teamActions} />
    </Page.Content>
  </Page>
}

/**
 * AppRoutes component.
 */

function AppRoutes({ ...props }) {
  return <Switch>
    <Route exact path="/" render={r => <ProjectsPage {...r.match.params} {...props} />} />
    <Route exact path="/projects" render={r => <ProjectsPage {...r.match.params} {...props} />} />
    <Route exact path="/tokens" render={r => <TokensPage {...r.match.params} {...props} /> } />
    <Route exact path="/tokens/add" render={r => <TokenPage {...r.match.params} {...props} /> } />
    <Route exact path="/projects/add" render={r => <ProjectEditPage {...r.match.params} {...props} /> } />
    <Route exact path="/projects/:project_id" render={r => <ProjectPage {...r.match.params} {...props} /> } />
    <Route exact path="/projects/:project_id/edit" render={r => <ProjectEditPage {...r.match.params} {...props} /> } />
    <Route exact path="/projects/:project_id/notifications" render={r => <NotificationsPage {...r.match.params} {...props} /> } />
    <Route exact path="/projects/:project_id/notifications/add" render={r => <NotificationPage {...r.match.params} {...props} /> } />
    <Route exact path="/projects/:project_id/notifications/:notification_id/edit" render={r => <NotificationPage {...r.match.params} {...props} /> } />
    <Route exact path="/projects/:project_id/alerts" render={r => <AlertsPage {...r.match.params} {...props} /> } />
    <Route exact path="/projects/:project_id/alerts/add" render={r => <AlertPage {...r.match.params} {...props} /> } />
    <Route exact path="/projects/:project_id/alerts/:alert_id/edit" render={r => <AlertPage {...r.match.params} {...props} /> } />
  </Switch>
}