
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import usePending from '@apex/lib/hooks/usePending'
import {
  Page,
  Button,
  Box,
  Text,
  Strong,
  Loader,
  List,
  EditButton,
  RemoveButton,
  Placeholder
} from '@apex/ui'

// TODO: rename name to description?

/**
 * NotificationsPage page.
 */

export default function NotificationsPage({ project_id, notifications, actions }) {
  useEffect(_ => {
    actions.loadNotifications({ project_id })
  }, [project_id])

  if (notifications == null) {
    return <React.Fragment>
      <Title project_id={project_id} />
      <Loader />
    </React.Fragment>
  }

  if (notifications.length == 0) {
    return <React.Fragment>
      <Title project_id={project_id} />
      <Placeholder title="No notifications" text="Manage alert notifications to Email, Slack, PagerDuty, Webhooks and more.">
        <AddButton project_id={project_id} />
      </Placeholder>
    </React.Fragment>
  }

  return <React.Fragment>
    <Title project_id={project_id} />
    <Page.Content.Wrapper>
      <List>
        {notifications.map((n, i) => {
          const last = i == notifications.length - 1
          return <List.Item key={n.id} isLast={last}>
            <Notification {...n} onRemove={e => actions.removeNotification({ project_id, notification_id: n.id }) } />
          </List.Item>
        })}
      </List>
    </Page.Content.Wrapper>
  </React.Fragment>
}

/**
 * Title component.
 */

function Title({ project_id }) {
  return <Page.Title text="Notifications">
    <AddButton project_id={project_id} />
  </Page.Title>
}

/**
 * AddButton component.
 */

function AddButton({ project_id }) {
  return <Button
  is={Link}
  to={`/projects/${project_id}/notifications/add`}
  theme="primary"
  >
    Add notification
  </Button>
}

/**
 * Notification component.
 */

function Notification({ id, project_id, name, type, onRemove }) {
  const [pending, remove] = usePending(onRemove)
  // TODO: customize per type
  const url = `/projects/${project_id}/notifications/${id}/edit`

  return <React.Fragment>
    <Box is={Link} to={url} textDecoration="none">
      <Strong>{name}</Strong>
      <Text marginLeft={2} muted>{type}</Text>
    </Box>
    <Box display="flex" marginLeft="auto">
      <EditButton is={Link} to={url} />
      <RemoveButton pending={pending} onClick={remove} />
    </Box>
  </React.Fragment>
}