
import React, { useState } from 'react'

/**
 * Key used for user config.
 */

const key = 'config'

/**
 * Config is the user config.
 */

let config = loadDefaults()

/**
 * useConfig returns the user's configuration from localStorage.
 */

export default function useConfig() {
  const [count, setCount] = useState(0)

  function update(changes) {
    const updated = { ...config, ...changes }
    save(updated)
    config = updated
    setCount(count + 1)
  }

  return [config, update]
}

/**
 * loadDefaults returns the saved or defaulted configuration.
 */

function loadDefaults() {
   const {
     timezone = 'utc',
     queryHistoryTab = 1,
     eventsMode = 'truncate',
     ...rest
   } = load()

   return {
     timezone,
     queryHistoryTab,
     eventsMode,
     ...rest
   }
}

/**
 * load returns the config from local storage or defaults.
 */

function load() {
  try {
    return JSON.parse(localStorage[key])
  } catch (err) {
    return {}
  }
}

/**
 * save the config to local storage.
 */

function save(config) {
  localStorage[key] = JSON.stringify(config)
}