
import React, { useEffect, useState, useRef } from 'react'
import Chart from 'd3-bar'
import './BarChart.css'

/**
 * BarChart component.
 */

export default function BarChart({ data, ...props }) {
  const [chart, setChart] = useState(null)
  const { width, height } = props
  const ref = useRef(null)

  useEffect(_ => {
    ref.current.innerHTML = ''

    const chart = new Chart({
      target: ref.current,
      axisPadding: 5,
      barPadding: 15,
      tickSize: 3,
      ease: 'easeElastic',
      color: ['RGB(0, 177, 240)', 'rgb(243, 43, 101)'],
      ...props
    })

    chart.render(data)
    setChart(chart)
  }, [width, height])

  useEffect(_ => {
    if (!chart) return
    chart.render(data)
  }, [data])

  return <svg ref={ref} className="BarChart"></svg>
}