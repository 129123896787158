
import React from 'react'
import { Nav } from '@apex/ui'
import { 
  AlertCircle,
  ArrowLeft,
  Layers,
  List,
  Lock,
  Users,
  MessageCircle,
  Settings,
} from 'react-feather'

/**
 * Navigation component.
 */

export function Navigation() {
  return <Nav>
    <Nav.Item icon={List} to={`/`} title="List projects">Projects</Nav.Item>
    <Nav.Item icon={Lock} to={`/tokens`} title="Manage API access tokens">API tokens</Nav.Item>
    <Nav.Item icon={Users} to={`/teams`} title="Team management">Teams</Nav.Item>
  </Nav>
}

/**
 * ProjectNavigation component.
 */

export function ProjectNavigation({ project_id }) {
  return <Nav>
    <Nav.Item icon={ArrowLeft} to={`/projects`} title="Back to projects">Back</Nav.Item>
    <Nav.Item icon={List} to={`/projects/${project_id}`} title="Search log events">Events</Nav.Item>
    <Nav.Item icon={AlertCircle} to={`/projects/${project_id}/alerts`} title="Manage alerting">Alerts</Nav.Item>
    <Nav.Item icon={MessageCircle} to={`/projects/${project_id}/notifications`} title="Manage alert notifications">Notifications</Nav.Item>
    <Nav.Item icon={Settings} to={`/projects/${project_id}/edit`} title="Edit project settings">Settings</Nav.Item>
  </Nav>
}

