
import React, { useState } from 'react'
import { Box, Text } from '@apex/ui'

/**
 * StatusBar component.
 */

export default function StatusBar({ children }) {
  return <Box
    display="flex"
    alignItems="stretch"
    paddingX={4}
    height={32}
    background={0}
    borderTop
    shadowTop
    width="100%"
    flex={false}
  >
    {children}
  </Box>
}

/**
 * Stat component.
 */

export function Stat({ name, value, title, setting, ...props }) {
  return <Box
    display="flex"
    alignItems="center"
    title={title}
    marginRight={2}
    paddingRight={2}
    borderRight
    cursor={setting ? 'pointer' : 'default'}
    {...props}
  >
    <Text size={0} userSelect="none" marginRight={1}>{name}:</Text>
    <Text size={0} userSelect={setting ? 'none' : 'all'} muted>{value}</Text>
  </Box>
}
