
import React from 'react'
import { truncate } from './lib/utils'
import {
  Box,
  HBox,
  Text,
  SideSheet,
  Paragraph,
  VBox
} from '@apex/ui'

/**
 * ObjectSheet component.
 */

export default function ObjectSheet({ text, subtext, object, onClose }) {
  return <SideSheet onCloseComplete={onClose}>
    <SideSheet.Title text={text} subtext={subtext} />
    <VBox
      scrollSnapType="y mandatory"
      overflowY="auto"
      scrollbars="none"
      background={1}
      inset
      flex
    >
      {Object.keys(object).map(key => {
        return <Property key={key} name={key} value={object[key]} />
      })}
    </VBox>
  </SideSheet>
}

/**
 * Property component.
 */

function Property({ name, value }) {
  return  <Row>
    <Box display="flex" justifyContent="space-between">
      <Paragraph size={1}>{name}</Paragraph>
      <Paragraph
        userSelect="all"
        textAlign="right"
        maxWidth="75%"
        size={1}
        marginLeft={2}
        muted>{''+value}</Paragraph>
    </Box>
  </Row> 
}

/**
 * Row component.
 */

function Row({ children, ...props }) {
  return  <Box
    className="Stat"
    scrollSnapAlign="end"
    paddingY={2}
    paddingX={6}
    borderBottom
    backgroundHover={2}
    {...props}
  >
    {children}
  </Box> 
}