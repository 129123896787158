
import React from 'react'
import useFocus from '@apex/lib/hooks/useFocus'
import {
  Form,
  TextInputField,
  Heading,
  Toggle,
  Paragraph,
  Text,
  HBox,
  VBox,
  Box,
} from '@apex/ui'

/**
 * TokenForm component.
 */

export default function TokenForm() {
  const focus = useFocus()
  return <React.Fragment>
    <Form.Row>
      <TextInputField
        name="description"
        label="Description"
        placeholder="Browser client write access"
        hint="Describe how the token will be used."
        autoComplete="none"
        innerRef={focus} />
    </Form.Row>
    <Form.Row>
      <VBox>
        <Box marginBottom={4}>
          <Heading>Scopes</Heading>
          <Paragraph marginTop={1} size={1} muted>Choose the the permissions available to this token.</Paragraph>
        </Box>
        <Box border borderRadius={2}>
          <Scope name="events" description="Access to log events." />
          <Scope name="alerts" description="Access to alerts." />
          <Scope name="notifications" description="Access to alert notifications." />
          <Scope name="projects" description="Access to projects." />
          <Scope name="searches" description="Access to saved searches." />
          <Scope name="tokens" description="Access to api tokens." />
        </Box>
      </VBox>
    </Form.Row>
  </React.Fragment>
}

/**
 * Scope component.
 */

function Scope({ name, description }) {
  return <HBox
    alignY="center"
    padding={4}
    borderBottom
  >
    <Box flex>
      <Paragraph>{name}</Paragraph>
      <Paragraph muted>{description}</Paragraph>
    </Box>
    <Toggle name={`${name}:read`} label="Read" />
    <Toggle name={`${name}:write`} label="Write" />
  </HBox>
}