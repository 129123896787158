
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import usePending from '@apex/lib/hooks/usePending'
import ObjectSheet from './ObjectSheet'
import {
  Page,
  Button,
  Box,
  HBox,
  Text,
  Strong,
  Loader,
  List,
  EditButton,
  RemoveButton,
  Placeholder,
  ConfirmationDialog,
  Paragraph
} from '@apex/ui'

/**
 * TokensPage page.
 */

export default function TokensPage({ tokens, actions }) {
  const [expanded, setExpanded] = useState(null)

  useEffect(_ => {
    actions.loadTokens()
  }, [])

  if (tokens == null) {
    return <React.Fragment>
      <Title />
      <Loader />
    </React.Fragment>
  }

  if (tokens.length == 0) {
    return <React.Fragment>
      <Title />
      <Placeholder title="No tokens" text="Manage API tokens to grant read and write access to projects, events, alerts and others.">
        <AddButton />
      </Placeholder>
    </React.Fragment>
  }

  return <React.Fragment>
    {expanded && <TokenSheet token={expanded} onClose={_ => setExpanded(null)}/>}

    <Title />
    <Page.Content.Wrapper>
      <List>
        {tokens.map((t, i) => {
          const last = i == tokens.length - 1
          return <List.Item key={t.id} isLast={last}>
            <Token
              {...t}
              onClick={_ => setExpanded(t)}
              onRemove={_ => actions.removeToken({ token_id: t.id }) } />
          </List.Item>
        })}
      </List>
    </Page.Content.Wrapper>
  </React.Fragment>
}

/**
 * Title component.
 */

function Title() {
  return <Page.Title text="Tokens">
    <AddButton />
  </Page.Title>
}

/**
 * AddButton component.
 */

function AddButton() {
  return <Button
    is={Link}
    to={`/tokens/add`}
    theme="primary"
  >
    Add token
  </Button>
}

/**
 * Token component.
 */

function Token({ id, description, scopes, onClick, onRemove }) {
  const [confirming, setConfirming] = useState(false)
  const [pending, remove] = usePending(onRemove)
  const [show, setShow] = useState(false)

  const dialog = confirming && <ConfirmationDialog
    text={`Are you sure you want to remove "${description}"?`}
    onCloseComplete={_ => setConfirming(false)}
    onConfirm={_ => remove()}
    confirmLabel="Remove"
    theme="danger"
  />

  return <React.Fragment>
    {dialog}
    <HBox alignX="between" marginRight={2} flex>
      <Strong cursor="pointer" onClick={onClick}>{description}</Strong>
      {show
        ? <Text userSelect="all" muted>{id}</Text>
        : <Text cursor="pointer" muted onClick={_ => setShow(true)}>show token</Text>}
    </HBox>
    <Box display="flex" marginLeft="auto">
      <RemoveButton pending={pending} onClick={e => setConfirming(true)} />
    </Box>
  </React.Fragment>
}

/**
 * TokenSheet component.
 */

function TokenSheet({ token, onClose }) {
  return <ObjectSheet
    text="Token"
    subtext="Viewing token."
    object={formatToken(token)}
    onClose={onClose} />
}

/**
 * formatToken returns a formatted token object.
 */

function formatToken(token) {
  return { 
    'token': token.id,
    'description': token.description,
    'scopes': token.scopes.join(', '),
    // 'last used': token.last_used_at, TODO: show last used at in UI
    'created': token.created_at
  }
}