
import React from 'react'
import { formatNumber, truncate } from './lib/utils'
import FieldFilter from './FieldFilter'
import {
  Box,
  VBox,
  Loader,
  Paragraph,
  SideSheet,
} from '@apex/ui'
import './FieldSheet.css'

/**
 * FieldSheet component.
 */

export default function FieldSheet({ field, stats, onFilter, onClose }) {
  const { name, type } = field

  return <SideSheet onCloseComplete={onClose}>
    <SideSheet.Title
      text="Field statistics"
      subtext={`Viewing stats for the ${type} field "${name}".`} />
    <ExpandedFieldStats name={name} type={type} stats={stats} onFilter={onFilter}  />
  </SideSheet>
}

/**
 * ExpandedFieldStats component.
 */

function ExpandedFieldStats({ name, type, stats, onFilter }) {
  function Stat({ children, ...props }) {
    return  <Box
      className="Stat"
      scrollSnapAlign="end"
      paddingY={2}
      paddingX={6}
      borderBottom
      backgroundHover={2}
      {...props}
    >
      {children}
    </Box> 
  }

  function stringStat({ value, count, percent }) {
    return <Stat key={value} cursor="pointer">
      <FieldFilter name={name} value={value} onSelect={onFilter} position="bottom left">
        <Box display="flex" justifyContent="space-between">
          <Paragraph size={1} truncate>{truncate(value)}</Paragraph>
          <Paragraph size={1} marginLeft={2} title={`${formatNumber(count)} events`} muted>{formatPercent(percent)}</Paragraph>
          <Box className="percent" width={`${percent * 100 | 0}%`}></Box>
        </Box>
      </FieldFilter>
    </Stat>
  }

  function booleanStat({ value, count, percent }) {
    return <Stat key={value} cursor="pointer">
      <FieldFilter name={name} value={value} onSelect={onFilter} position="bottom left">
        <Box display="flex" justifyContent="space-between">
          <Paragraph size={1}>{'' + value}</Paragraph>
          <Paragraph size={1} marginLeft={2} title={`${formatNumber(count)} events`} muted>{formatPercent(percent)}</Paragraph>
          <Box className="percent" width={`${percent * 100 | 0}%`}></Box>
        </Box>
      </FieldFilter>
    </Stat>
  }

  function numericStat({ min, max, avg }) {
    return <React.Fragment>
      <Stat display="flex" justifyContent="space-between">
        <Paragraph size={1} muted>Minimum</Paragraph>
        <Paragraph size={1}>{formatNumber(min)}</Paragraph>
      </Stat>
      <Stat display="flex" justifyContent="space-between">
        <Paragraph size={1} muted>Average</Paragraph>
        <Paragraph size={1}>{formatNumber(avg)}</Paragraph>
      </Stat>
      <Stat display="flex" justifyContent="space-between">
        <Paragraph size={1} muted>Maximum</Paragraph>
        <Paragraph size={1}>{formatNumber(max)}</Paragraph>
      </Stat>
    </React.Fragment>
  }

  function content() {
    if (stats == null) {
      return <Loader />
    }

    switch (type) {
      case 'string':
        return stats.map(stringStat)
      case 'boolean':
        return stats.map(booleanStat)
      case 'number':
        return numericStat(stats)
      default:
        return 'unhandled type'
    }
  }

  return <VBox
    scrollSnapType="y mandatory"
    overflowY="auto"
    scrollbars="none"
    background={1}
    inset
    flex
  >
    {content()}
  </VBox>
}
/**
 * formatPercent returns a formatted percentage.
 */

function formatPercent(n) {
  return `${n * 100 | 0}%`
}
