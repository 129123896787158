
import React, { useEffect, useState, useRef } from 'react'
import usePending from '@apex/lib/hooks/usePending'
import useFocus from '@apex/lib/hooks/useFocus'
import useRedirect from './lib/useRedirect'
import NotificationForm from './NotificationForm'
import {
  Button,
  Form,
  Loader,
  Page,
  SelectField,
  TextInputField,
  toaster
} from '@apex/ui'

/**
 * AlertPage component.
 */

export default function AlertPage({ project_id, alerts, alert_id, notifications = [], actions, ...props }) {
  const [redirect, saved] = useRedirect(`/projects/${project_id}/alerts`)
  const [notificationId, setNotificationId] = useState(undefined)
  const isNew = alert_id == null

  // load alerts and notifications
  useEffect(_ => {
    actions.loadNotifications({ project_id })
    actions.loadAlerts({ project_id })
  }, [project_id])

  // pending alerts
  if (alerts == null) {
    return <React.Fragment>
      <Page.Title text={isNew ? 'Add alert' : 'Edit alert'} />
      <Loader />
    </React.Fragment>
  }

  // existing alert
  const alert = alerts.find(a => a.id == alert_id) || {}

  async function onSubmit({ alert, notification }) {
    // new notification
    if (notification) {
      const { id } = await actions.addNotification(notification)
      alert.notification_id = id
    }
    
    // alert
    if (isNew) {
      await actions.addAlert(alert)
    } else {
      await actions.updateAlert(alert)
    }

    toaster.success('Saved alert')
    saved()
  }
  
  async function onTest({ alert, notification }) {
    // new notification
    if (notification) {
      const { id } = await actions.addNotification(notification)
      alert.notification_id = id
      
      // reload notifications so that creation and further testing
      // of the alert will not create duplicate notifications
      await actions.loadNotifications({ project_id })

      setNotificationId(id)
    }
    
    await actions.testAlert(alert)
    toaster.success('Test alert triggered')
  }

  return <React.Fragment>
    {redirect}
    <Page.Title text={isNew ? 'Add alert' : 'Edit alert'} />
    
    <Page.Content.Wrapper>
      <AlertForm
        alert={alert}
        notification_id={notificationId === undefined ? alert.notification_id : notificationId}
        notifications={notifications || []}
        project_id={project_id}
        onChangeNotification={setNotificationId}
        onTest={onTest}
        onSubmit={onSubmit} />
    </Page.Content.Wrapper>
  </React.Fragment>
}

/**
 * AlertForm component.
 */

function AlertForm({ alert, notifications, notification_id, project_id, onChangeNotification, onSubmit, onTest }) {
  const { 
    id,
    name,
    description,
    query,
    muted,
    severity = 'error',
    operator = '>=',
    interval = 5,
    threshold,
    created_at
  } = alert

  const focus = useFocus()

  // we select the notification instead of directly passing notification_id
  // to ensure it actually exists, otherwise the "new notification" form
  // will not be displayed when it is deleted.
  const notification = notifications.find(n => n.id == notification_id) || {}

  const [pending, submit] = usePending(({ op, alert, notification }) => {
    const e = {
      alert: {
        ...alert,
        project_id,
        muted: alert.muted == 'true',
        threshold: parseInt(alert.threshold, 10),
        interval: parseInt(alert.interval, 10),
        limit: 100,
      }
    }

    if (notification) {
      e.notification = {
        ...notification,
        project_id,
        email_addresses: maybeSplit(notification.email_addresses),
        sms_numbers: maybeSplit(notification.sms_numbers)
      }
    }

    if (op == 'test') {
      return onTest(e)
    }
    
    return onSubmit(e)
  })

  return <Form onSubmit={submit}>
    <input type="hidden" name="alert[id]" value={id} />
    <input type="hidden" name="alert[created_at]" value={created_at} />

    <Form.Row>
      <TextInputField
        innerRef={focus}
        name="alert[name]"
        placeholder="Errors"
        label="Name"
        defaultValue={name}
        autoComplete="none"
        required />
    </Form.Row>
    <Form.Row>
      <TextInputField
        name="alert[description]"
        placeholder="All production errors"
        defaultValue={description}
        label="Description"
        autoComplete="none" />
    </Form.Row>
    <Form.Row>
      <TextInputField
        name="alert[query]"
        placeholder="level >= error"
        label="Query"
        defaultValue={query}
        required />
    </Form.Row>
    <Form.Row>
      <OperatorSelect
        name="alert[operator]"
        label="Operator"
        defaultValue={operator}
        required />
      
      <TextInputField
        name="alert[threshold]"
        placeholder="1"
        label="Threshold"
        defaultValue={threshold} required />
      
      <IntervalSelect
        name="alert[interval]"
        label="Interval"
        defaultValue={interval}
        required />
    </Form.Row>

    <input
      name="alert[muted]"
      defaultValue={muted}
      type="hidden"
      required />

    <input
      name="alert[severity]"
      defaultValue={severity}
      type="hidden"
      required />
    
    <AlertNotifications
      value={notification.id}
      notifications={notifications}
      onChange={v => onChangeNotification(v == '' ? null : v) } />
    
    <Form.Actions>
      <Button name="op" value="test" pending={pending}>Test alert</Button>
      <Button name="op" value="save" theme="primary" pending={pending}>Save alert</Button>
    </Form.Actions>
  </Form>
}

/**
 * AlertNotifications form.
 */

function AlertNotifications({ value, notifications = [], onChange }) {
  const showAddForm = value == null

  return <React.Fragment>
    <Form.Row>
      <SelectField
        name="alert[notification_id]"
        label="Notification"
        help="Create a new notification or select from existing ones."
        value={value}
        onChange={e => onChange(e.target.value)}
      >
        <option value="">Create new</option>
        {notifications.map(n => {
          const { id, name } = n
          return <option key={id} value={id}>{name}</option>
        })}
      </SelectField>
    </Form.Row>

    {showAddForm && <NotificationForm />}
  </React.Fragment>
}

/**
 * IntervalSelect component.
 */

function IntervalSelect({ ...props }) {
  return <SelectField {...props}>
    <option value="1">Every minute</option>
    <option value="5">Every 5 minutes</option>
    <option value="10">Every 10 minutes</option>
    <option value="15">Every 15 minutes</option>
    <option value="30">Every 30 minutes</option>
    <option value="60">Every hour</option>
    <option value="360">Every 6 hours</option>
    <option value="720">Every 12 hours</option>
    <option value="1440">Every day</option>
  </SelectField>
}

/**
 * SeveritySelect component.
 */

function SeveritySelect({ ...props }) {
  return <SelectField {...props}>
    <option value="info">Info</option>
    <option value="notice">Notice</option>
    <option value="error">Error</option>
    <option value="critical">Critical</option>
  </SelectField>
}

/**
 * OperatorSelect component.
 */

function OperatorSelect({ ...props }) {
  return <SelectField {...props}>
    <option value=">=">Greater than or equal to</option>
    <option value="<=">Less than or equal to</option>
    <option value=">">Greater than</option>
    <option value="<">Less than</option>
  </SelectField>
}

/**
 * Maybe split returns the split string or null.
 */

function maybeSplit(s) {
  return s ? s.split(',') : null
}