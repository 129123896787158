
import React, { useState, useEffect } from 'react'
import { getRelativeTimeOptions } from './lib/utils'
import { HelpCircle } from 'react-feather'
import { withRouter } from 'react-router-dom'
import useConfig from './lib/useConfig'
import copyToClipboard from './lib/clipboard'
import QuerySettingsButton from './QuerySettingsButton'
import QueryHistoryButton from './QueryHistoryButton'
import TimeRangeButton from './TimeRangeButton'
import useRecentQueries from './lib/useRecentQueries'
import querystring from 'query-string'
import QueryHistory from './QueryHistory'
import StatusBar, { Stat } from './StatusBar'
import FieldSheet from './FieldSheet'
import LogSheet from './LogSheet'
import QueryHelp from './QueryHelp'
import Timeseries from './Timeseries'
import Fields from './Fields'
import Events from './Events'
import bytes from 'bytes'
import ms from 'ms'
import {
  Box,
  VBox,
  HBox,
  Button,
  Menu,
  Popover,
  Form,
  Page,
  QueryInput,
} from '@apex/ui'

/**
 * Relative time options.
 */

const relativeTimeOptions = getRelativeTimeOptions()

/**
 * ProjectPage page.
 */

export default withRouter(ProjectPage)

function ProjectPage({ project_id, projects, events, timeseries, fields, searches, fieldStats = {}, actions, history, theme, onChangeTheme }) {
  const [relativeTime, setRelativeTime] = useState(defaultRelativeTime)
  const [expandedLog, setExpandedLog] = useState(null)
  const [expandedField, setExpandedField] = useState(null)
  const [showHelp, setShowHelp] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [limit, setLimit] = useState(500)
  const [config, setConfig] = useConfig()
  const [query, setQuery] = useState(defaultQuery)
  const [recentQueries, addRecentQuery] = useRecentQueries({ max: 50 })
  const project = projects.find(p => p.id == project_id)
  const { id } = project

  // load saved searches and discovered fields
  useEffect(_ => {
    const start = new Date(Date.now() - 3.6e6) // hour
    const stop = new Date

    actions.loadSearches({ project_id })

    if (window.location.search) {
      search({ query })
    } else {
      actions.loadDiscoveredFields({
        project_id: id,
        query,
        start,
        stop
      })
    }

    return _ => {
      actions.resetEvents()
    }
  }, [project_id])
  
  async function search({ query = '' }) {
    const start = new Date(Date.now() - relativeTime.milliseconds)
    const stop =  new Date

    if (query.trim()) {
      addRecentQuery(query)
    }

    actions.search({
      project_id: id,
      start,
      stop,
      limit,
      query
    })

    actions.loadTimeseries({
      project_id: id,
      max_points: 300,
      start,
      stop,
      query
    })

    actions.loadDiscoveredFields({
      project_id: id,
      query,
      start,
      stop
    })

    const qs = querystring.stringify({
      query,
      relativeTime: relativeTime.value
    })

    history.push(`?${qs}`)
  }

  function loadFieldStats(field) {
    // TODO: maybe only sample the past hour?
    const startTime = new Date(Date.now() - relativeTime.milliseconds)
    const stopTime =  new Date

    actions.loadFieldStats({
      project_id: id,
      field: field.name,
      type: field.type,
      start: startTime,
      stop: stopTime,
      query,
      limit: 100
    })

    setExpandedField(field)
  }

  function changeTimezone(timezone) {
    setConfig({ timezone })
  }

  function changeEventsMode(eventsMode) {
    setConfig({ eventsMode })
  }

  function queryFromHistory({ query }) {
    setQuery(query)
    search({ query })
    // TODO: focus search input
  }

  function onFilter({ op, name, value }) {
    if (op == 'copy') {
      copyToClipboard(value)
      return
    }
    const q = filterQuery(query, { op, name, value })
    setQuery(q)
    search({ query: q })
  }

  function addSavedQuery({ query }) {
    actions.addSearch({
      project_id,
      query,
      name: query
    })
  }

  function updateSearch(params) {
    actions.updateSearch(params)
  }

  function removeSearch({ id }) {
    actions.removeSearch({
      project_id,
      search_id: id
    })
  }

  // TODO: move
  const top = <Form onSubmit={search}>
    <HBox alignY="center" paddingX={4} gap={2} height="100%" flex>
      <QueryInput
        name="query"
        value={query}
        onChange={e => setQuery(e.target.value)}
        width="100%" />

      <QueryHistoryButton
        theme="default"
        onClick={_ => setShowHistory(true)} />

      <QueryHelpButton 
        theme="default"     
        onClick={_ => setShowHelp(true)} />

      <QuerySettingsButton
        theme="default"
        themeName={theme}
        onChangeTheme={onChangeTheme}
        timezone={config.timezone}
        onChangeTimezone={changeTimezone}
        eventsMode={config.eventsMode}
        onChangeEventsMode={changeEventsMode} />

      <TimeRangeButton
        selected={relativeTime}
        onChange={setRelativeTime} />

      <Button theme="primary" type="submit">Search</Button>
    </HBox>
  </Form>

  // TODO: remove defaultValue
  // TODO: disable when query is pending, allow cancel
  // TODO: responsive chart resizing
  return <React.Fragment>
    {showHelp && <QueryHelp
      onClose={_ => setShowHelp(false)} />}
    
    {expandedLog && <LogSheet
      timezone={config.timezone}
      log={expandedLog.event}
      onFilter={onFilter}
      onClose={_ => setExpandedLog(null)} />}
    
    {expandedField && <FieldSheet
      field={expandedField}
      stats={fieldStats[expandedField.name]}
      onFilter={onFilter}
      onClose={_ => setExpandedField(null)} />}

    {showHistory && <QueryHistory
      saved={searches}
      recent={recentQueries}
      onSelect={queryFromHistory}
      onAdd={addSavedQuery}
      onUpdate={updateSearch}
      onRemove={removeSearch}
      onClose={_ => setShowHistory(false)} />}

    <TopPane>{top}</TopPane>

    <HBox overflowY="hidden" flex>
      <VBox minWidth={500} flex>
        <Timeseries
          series={timeseries}
          timezone={config.timezone} />

        <Events
          highlight={expandedLog && expandedLog.index}
          timezone={config.timezone}
          mode={config.eventsMode}
          onExpand={e => setExpandedLog(e)}
          onShowHelp={_ => setShowHelp(true)}
          onFilter={onFilter}
          {...events}
        >
          <LoadMoreButton
            onClick={_ => actions.searchMore()}
            pending={events && events.morePending} />
        </Events>
      </VBox>

      <Fields
        fields={fields}
        onFieldClick={loadFieldStats} />
    </HBox>        
    <StatusPane {...events} limit={limit} onChangeLimit={setLimit} />
  </React.Fragment>
}

/**
 * LoadMoreButton component.
 */

function LoadMoreButton({ ...props }) {
  return <HBox align="center" paddingY={2}>
    <Button theme="minimal" {...props}>Load more events</Button>   
  </HBox>
}

/**
 * StatusPane component.
 */

function StatusPane({ pending, results, stats, limit, onChangeLimit }) {
  if (pending || pending == null) {
    return <StatusBar></StatusBar>
  }

  const { total_bytes_processed, total_bytes_billed, cache_hit, duration } = stats

  return <StatusBar>
    <LimitButton limit={limit} onChange={onChangeLimit} />
    <Stat name="Matched" title="Total matching events shown." value={results.length} />
    <Stat name="Duration" title="Total duration of the search query." value={ms(duration)} />
    <Stat name="Processed" title="Total bytes processed by the query." value={bytes.format(total_bytes_billed)} />
  </StatusBar>
}

/**
 * LimitButton component.
 */

function LimitButton({ limit, onChange }) {
  function content() {
    return <Menu full>
      <Menu.OptionsGroup
        options={[
          { label: '1000', value: 1000 },
          { label: '500', value: 500 },
          { label: '250', value: 250 },
          { label: '100', value: 100 },
          { label: '50', value: 50 }
        ]}
        selected={limit}
        onChange={onChange}
      />
    </Menu>
  }
  
  return <Popover content={content} position="top left">
    <Stat
      name="Limit"
      title="The max number of search results."
      value={limit}
      setting />
  </Popover>
}

/**
 * TopPane component.
 */

function TopPane({ children }) {
  return <VBox
    alignX="between"
    alignY="center"
    height="3.5rem"
    background={0}
    borderBottom
    shadowBottom
  >
    {children}
  </VBox>
}

/**
 * QueryHelpButton component.
 */

function QueryHelpButton(props) {
  return <Button
    is="a"
    icon={HelpCircle}
    theme="minimal"
    title="Show search query examples."
    {...props} />
}

/**
 * Filter query operators.
 */

const operators = {
  show: '=',
  hide: '!=',
  above: '>',
  below: '<',
  gte: '>=',
  lte: '<='
}

/**
 * Filter query helper.
 */

function filterQuery(query, { op, name, value }) {
  const v = typeof value == 'string'
    ? `"${value.replace(/"/g, '\\"')}"`
    : `${value}`
  
  const operator = operators[op]
  const prefix = query.trim()
  
  return prefix == ''
    ? `${name} ${operator} ${v}`
    : `${query} and ${name} ${operator} ${v}`
}

/**
 * defaultQuery returns `query` parsed from the querystring or an empty string.
 */

function defaultQuery() {
  const { query } = parseQuery()
  return query || ''
}

/**
 * defaultRelativeTime returns `relativeTime` parsed from the querystring, or the first relativeTimeOptions.
 */

function defaultRelativeTime() {
  const { relativeTime } = parseQuery()
  return relativeTime
    ? relativeTimeOptions.find(v => v.value == relativeTime)
    : relativeTimeOptions[0]
}

/**
 * parseQuery returns a parsed query-string.
 */

function parseQuery() {
  return querystring.parse(window.location.search, { parseNumbers: true })
}
