
import React, { useState } from 'react'

/**
 * Key used for query history.
 */

const key = 'recentQueries'

/**
 * useRecentQueries returns up to max recent queries, and a function to add them.
 */

export default function useRecentQueries({ max = 10 } = {}) {
  const [items, setItems] = useState(load)

  function add(query) {
    const updated = unique([query].concat(items).slice(0, max))
    setItems(updated)
    save(updated)
  }

  return [items, add]
}

/**
 * load items from local storage.
 */

function load() {
  try {
    return JSON.parse(localStorage[key])
  } catch (err) {
    return []
  }
}

/**
 * save items to local storage.
 */

function save(items) {
  localStorage[key] = JSON.stringify(items)
}

/**
 * unique util.
 */

function unique(arr) {
  const set = new Set(arr)
  return Array.from(set.values())
}